import { useMemo } from 'react';
import { defineMessage } from 'react-intl';
import SMOCIcon from 'utilihive-icons/MeteringPoint';
import MapIcon from 'utilihive-icons/Map';
import GraphQLIcon from 'utilihive-icons/DataServices';
import DashboardIcon from 'utilihive-icons/Dashboard';
import BugReportIcon from '@mui/icons-material/BugReport';
import OverviewIcon from 'utilihive-icons/Overview';
import MeteringPointIcon from 'utilihive-icons/MeteringPoint';

import MODULE_NAMES from 'App/config/moduleNames';
import { FEATURE_TOGGLES } from 'App/ducks/featureDuck';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

export const MODULE_PATH = 'smoc';

export const OVERVIEW = '';
export const MAP = 'map';
export const DOCUMENTATION = 'graphql';
export const ISSUES = 'issues';
export const REPORTS = 'dashboard';
export const USAGE_POINT = 'usage-points/:id/*';
export const ASSETS = 'assets';
export const MISSING_DATA = 'missing-data';

export const USAGE_POINT_METER_READINGS = 'meter-readings';
export const USAGE_POINT_MESSAGE_LOG = 'message-log';
export const USAGE_POINT_RELATED_ASSETS = 'related-assets';

const paramFields = ['fromDate', 'toDate', 'dateOptionIndex', 'offset'];

const getParams = search => {
  const cleaned = search.replace('?', '');
  const params = qs.parse(cleaned);
  const newParams = {};
  paramFields.forEach(fieldKey => {
    if (params[fieldKey]) {
      newParams[fieldKey] = params[fieldKey];
    }
  });
  const stringified = qs.stringify(newParams);
  return stringified ? `?${stringified}` : '';
};

const children = [
  {
    index: true,
    path: OVERVIEW,
    lazy: () => import(/* webpackChunkName: "SMOC:Overview" */ '../scenes/Overview'),
    intlMessage: defineMessage({ id: 'route.smoc.overview', defaultMessage: 'Overview' }),
    Icon: OverviewIcon,
  },
  {
    path: MAP,
    lazy: () => import(/* webpackChunkName: "SMOC:Map" */ '../scenes/Map'),
    intlMessage: defineMessage({ id: 'route.smoc.map', defaultMessage: 'Map' }),
    Icon: MapIcon,
  },
  {
    path: DOCUMENTATION,
    lazy: () => import(/* webpackChunkName: "SMOC:DataLakeGraphQL" */ 'Datalake/scenes/DataLakeGraphQL'),
    intlMessage: defineMessage({ id: 'route.smoc.documentation', defaultMessage: 'Data Services' }),
    Icon: GraphQLIcon,
  },
  {
    path: REPORTS,
    lazy: () => import(/* webpackChunkName: "SMOC:Reports" */ 'Vee/scenes/VEEDashboard'),
    intlMessage: defineMessage({ id: 'route.smoc.reports', defaultMessage: 'Reports' }),
    Icon: DashboardIcon,
  },
  {
    path: ISSUES,
    lazy: () => import(/* webpackChunkName: "SMOC:Issues" */ '../scenes/Issues'),
    intlMessage: defineMessage({ id: 'route.smoc.issues', defaultMessage: 'Issues' }),
    Icon: BugReportIcon,
  },
  {
    path: ASSETS,
    lazy: () => import(/* webpackChunkName: "SMOC:Assets" */ '../scenes/Assets'),
    intlMessage: defineMessage({ id: 'route.smoc.assets', defaultMessage: 'Assets' }),
    Icon: MeteringPointIcon,
  },
  {
    path: MISSING_DATA,
    lazy: () => import(/* webpackChunkName: "SMOC:MissingData" */ '../scenes/MissingData'),
    intlMessage: defineMessage({ id: 'route.smoc.missingData', defaultMessage: 'Missing data' }),
    Icon: MeteringPointIcon,
    featureToggle: FEATURE_TOGGLES.SMOC_DASHBOARD,
  },
  {
    path: USAGE_POINT,
    lazy: () => import(/* webpackChunkName: "SMOC:UsagePoint" */ '../scenes/UsagePoint'),
  },
];

export const smoc = {
  path: MODULE_PATH,
  lazy: () => import(/* webpackChunkName: "SMOC" */ 'SMOC'),
  intlMessage: defineMessage({ id: 'route.smoc', defaultMessage: 'SMOC' }),
  Icon: <SMOCIcon />,
  moduleNameForOrganisationAccess: MODULE_NAMES.SMOC,
  children,
};

export const useRoutes = () => {
  const location = useLocation();
  const routes = useMemo(
    () => ({
      intlMessage: smoc.intlMessage,
      Icon: smoc.Icon,
      root: smoc.path,
      moduleNameForOrganisationAccess: smoc.moduleNameForOrganisationAccess,
      routes: smoc.children
        .filter(child => !!child.intlMessage)
        .map(({ path, Icon, intlMessage, index }) => ({
          path,
          intlMessage,
          Icon,
          index,
          params: getParams(location.search),
        })),
    }),
    [location.search]
  );
  return routes;
};

export default smoc;
