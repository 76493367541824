import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useFeatureToggle, FEATURE_TOGGLES } from 'sharedComponents/FeatureToggle';

const Scene = ({ className, noMargin, noBar, noMenu, ...props }) => {
  const geThemeFeature = useFeatureToggle(FEATURE_TOGGLES.GE_THEME);
  const sxMargins = [noMargin ? { ml: 0 } : { mt: 1, mx: 2, mb: 0 }, noMenu && { ml: 0 }];
  return (
    <Box
      sx={[
        noBar
          ? {
              height: geThemeFeature ? 'calc(100vh - 104px)' : '100vh',
              overflowY: 'auto',
            }
          : { height: geThemeFeature ? 'calc(100vh - 168px)' : 'calc(100vh - 64px)', overflowY: 'auto' },
      ]}
    >
      <Box
        className={className}
        sx={[
          {
            overflowX: 'hidden',
          },
        ].concat(geThemeFeature ? { m: 4 } : sxMargins)}
        {...props}
      />
    </Box>
  );
};

Scene.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  noMargin: PropTypes.bool,
  noBar: PropTypes.bool,
};

Scene.defaultProps = {
  className: undefined,
  noMargin: false,
  noBar: false,
};

export default Scene;
