import React, { useCallback, useEffect, useState } from 'react';
import { useLinkClickHandler, useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Logout from './Logout';
import Meatball from '../../presentation/components/Logo/General_Electric_logo.svg?react';
import { navRoutes, Documentation } from '../../config/routes';

const Navigation = ({ organizationShortName, fetchFeatures, logout }) => {
  const onDocumentationClick = useCallback(() => {
    window.location = Documentation.path;
  }, []);
  const onProfileClick = useLinkClickHandler('/settings/user');
  useEffect(() => {
    if (organizationShortName) {
      fetchFeatures();
    }
  }, [fetchFeatures, organizationShortName]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const current = location.pathname.split('/')[1];
  const onSelect = useCallback(
    e => {
      navigate('/' + e.target.value);
    },
    [navigate]
  );
  return (
    <AppBar sx={{ bgcolor: 'surface.7' }}>
      <Toolbar>
        <Meatball height={42} width={42} />
        <Typography variant="body1" component="div" sx={{ ml: 2, fontSize: 18 }}>
          <FormattedMessage id="general.utilihive" defaultMessage="Utilihive" />
        </Typography>
        <Select
          id="nav-bar-select"
          value={current}
          label="Module"
          sx={{ ml: 3, minWidth: 200, border: 0 }}
          onChange={onSelect}
        >
          {navRoutes.map(route => (
            <MenuItem key={route.path} value={route.path}>
              <FormattedMessage {...route.intlMessage} />
            </MenuItem>
          ))}
        </Select>
        <Box sx={{ flexGrow: 3 }} />
        <IconButton onClick={e => onProfileClick(e)} color="inherit">
          <AccountCircle />
        </IconButton>
        <Box sx={{ ml: 1, mr: 1, width: 0, borderLeft: '1px solid', borderColor: 'borders.moderate', height: 50 }} />
        <IconButton onClick={handleClick} color="inherit">
          <MoreVertIcon />
        </IconButton>
      </Toolbar>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={onDocumentationClick}>
          <FormattedMessage {...Documentation.intlMessage} />
        </MenuItem>
        <Divider />
        <Logout logout={logout} />
      </Menu>
    </AppBar>
  );
};

export default Navigation;
